import React, { useState } from "react";
import {
  collection,
  doc,
  setDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { db } from "../config/fb";

export default function AddAdmin() {
  const [email, setEmail] = useState("");

  const userRef = collection(db, "users");

  const AddAdmin = async (e) => {
    e.preventDefault();
    const q = query(userRef, where("email", "==", email));
    try {
      const querySnapshot = await getDocs(q);
      console.log("querySnapshot", querySnapshot.empty);
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async (res) => {
          const uid = res.id;
          console.log("uid", uid);
          await setDoc(doc(db, "Admin", uid), {}).then(() => {
            setEmail("");
          });
        });
      } else {
        alert("User not found");
      }
    } catch (error) {
      console.error("AddAdmin error: " + error);
    } finally {
      setEmail("");
      alert(`you have added ${email} as an admin`);
    }
  };

  return (
    <div>
      <h1>Add New Admin</h1>
      <form
        onSubmit={AddAdmin}
        autoComplete="on"
        className="auth-inner"
        id="add admin form"
      >
        <div className="items-center">
          <input
            id="email"
            type="email"
            autoComplete="auto"
            placeholder="Enter email address"
            className="inputBox"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div>
          <button
            className="loginBtn"
            type="submit"
            style={{ backgroundColor: "#4285F4", marginTop: "20px" }}
          >
            Add New Admin
          </button>
        </div>
      </form>
    </div>
  );
}
