import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircleLoader } from "react-spinners";

import MyImageList from "./MyImageList";
import {
  setLoadingImages,
  usersSelectors,
} from "../../redux/reducers/usersSlice";
import {
  GetPromotionalImagePost,
  LoadMorePromotionalImagePost,
} from "../../redux/actions/users";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export default function GetUsersPostImage() {
  const { ids, loadingImages, lastPostDoc } = useSelector(
    (store) => store.usersState
  );
  // const usersInfo = usersSelectors.selectAll(state);

  const [promotionalPost, setPromotionalPost] = useState([]);
  const [color, setColor] = useState("#ffffff");

  // console.log("ids.length", ids.length);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetPromotionalImagePost());
  }, []);

  useEffect(() => {
    if (ids.length !== 0) {
      setPromotionalPost(ids);
    }
  }, [ids]);

  const handleLoadingMoreImages = () => {
    console.log("lastPostDoc", lastPostDoc);
    if (lastPostDoc !== null) {
      dispatch(setLoadingImages(true));
      dispatch(LoadMorePromotionalImagePost());
    }
  };

  // if (loadingImages) {
  //   return (
  //     <div
  //       style={{
  //         justifyContent: "center",
  //         alignItems: "center",
  //         marginTop: 100,
  //         display: "flex",
  //       }}
  //     >
  //       <h1>Loading Images ......</h1>
  //     </div>
  //   );
  // }

  return (
    <div className="">
      <h1>Promotional Post Images</h1>
      {promotionalPost.length !== 0 ? (
        <MyImageList data={promotionalPost} />
      ) : null}
      <CircleLoader
        color={"#0bb005"}
        loading={loadingImages}
        cssOverride={override}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <div className="flex justify-center">
        <button onClick={handleLoadingMoreImages}>Load More</button>
      </div>
    </div>
  );
}
