import React from "react";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { useSelector, useDispatch } from "react-redux";

export default function MyImageList(props) {
  const { data } = props;

  const { entities } = useSelector((store) => store.usersState);

  // console.log("entities", entities);

  const handleDownloadImage = async (imgUrl, id) => {
    const imageblob = await fetch(imgUrl)
      .then((response) => response.arrayBuffer())
      .then((buffer) => new Blob([buffer], { type: "image/jpeg" }));
    const link = document.createElement("a");
    link.href = URL.createObjectURL(imageblob);
    link.style.display = "none";
    link.download = id;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <ImageList sx={{ width: "100%", height: "10%" }} cols={3}>
      {data.length !== 0 &&
        data.map((id) => {
          const item = entities[id];
          return (
            <ImageListItem key={id} sx={{ padding: "5px" }}>
              <img
                srcSet={`${item?.url[0]}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                src={`${item?.url[0]}?w=164&h=164&fit=crop&auto=format`}
                alt={item.id}
                loading="lazy"
                onClick={() => openInNewTab(item?.url[0])}
              />
              <ImageListItemBar
                sx={{ margin: "5px" }}
                title={item.user?.displayName}
                // subtitle={item.caption}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255)" }}
                    aria-label={"download"}
                    onClick={() => handleDownloadImage(item?.url[0], item.id)}
                  >
                    <CloudDownloadOutlinedIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          );
        })}
    </ImageList>
  );

  // return (
  //   <ImageList sx={{ width: "100%", height: "20%" }} cols={3}>
  //     {data.map((id) => {
  //       const item = entities[id];
  //       return (
  //         <ImageListItem key={id} sx={{ padding: "5px" }}>
  //           <img
  //             srcSet={`${item?.postUrl[0]}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
  //             src={`${item?.postUrl[0]}?w=164&h=164&fit=crop&auto=format`}
  //             alt={item.id}
  //             loading="lazy"
  //             onClick={() => openInNewTab(item?.postUrl[0])}
  //           />
  //           <ImageListItemBar
  //             sx={{ margin: "5px" }}
  //             title={item.user?.displayName}
  //             subtitle={item.caption}
  //             actionIcon={
  //               <IconButton
  //                 sx={{ color: "rgba(255, 255, 255)" }}
  //                 aria-label={`info about ${"download"}`}
  //                 onClick={() => handleDownloadImage(item?.postUrl[0], item.id)}
  //               >
  //                 <CloudDownloadOutlinedIcon />
  //               </IconButton>
  //             }
  //           />
  //         </ImageListItem>
  //       );
  //     })}
  //   </ImageList>
  // );
}
